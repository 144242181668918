@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Medium.ttf") format("truetype");
    font-weight:500;
    font-style:normal;
    font-display:swap;
}
    
@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Regular.ttf") format("truetype");
    font-weight:400;
    font-style:normal;
    font-display:swap;
}
    
@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Bold.ttf") format("truetype");
    font-weight:700;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Medium.ttf") format("truetype");
    font-weight:500;
    font-style:normal;
    font-display:swap;
}
    
@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Regular.ttf") format("truetype");
    font-weight:400;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Bold.ttf") format("truetype");
    font-weight:700;
    font-style:normal;
    font-display:swap;
}    
    
@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Medium.ttf") format("truetype");
    font-weight:500;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Regular.ttf") format("truetype");
    font-weight:400;
    font-style:normal;
    font-display:swap;
}

@font-face {
    font-family:"Googlesans";
    src:url("../fonts/GoogleSans-Bold.ttf") format("truetype");
    font-weight:700;
    font-style:normal;
    font-display:swap;
}