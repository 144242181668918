.easter-egg {
    &--charlie-watts {
        z-index: 100;
        position: fixed;
        top: 50%;
        left: 50%;
        animation: charlie-watts 2s ease-in;
        animation-fill-mode: forwards;
    }
}

@keyframes charlie-watts {
    from {
        transform: translate(-50%, -50%) rotate(0);
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translate(-50%, -50%) rotate(360deg);
    }
}