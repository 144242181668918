.lucky-button {
    background: #F8F9FA;
    border: 1px solid #4285F4;
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 10;
    color: black;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    cursor: pointer;
    margin: 0 0.2rem;
}