.language-switcher {
    background: none;
    border: none;
    box-sizing: border-box;
    z-index: 2;
    color: rgba(0, 0, 0, 0.8);
    padding: 0;
    font-size: 1.4rem;
    cursor: pointer;
    margin: 0 0.2rem;
    position: absolute;
    padding: 0 0 0 2.5rem;
    top: 2.1rem;
    left: 2rem;
    background-repeat: no-repeat;
    background-image: url('../images/icons/belgium.svg');
}