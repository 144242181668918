.modal {
    position: fixed;
    animation: fadeIn 1s;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    padding: 0 2rem;

    &__close {
        position: absolute;
        right: 3rem;
        top: 2rem;
        width: 26px;
        height: 30px;
        background: url('../../images/icons/close.svg') no-repeat;
        border: none;
        cursor: pointer;
    }

    &__dialog {
        position: relative;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;

        max-width: 800px;
        margin: 1.75rem auto;     
        
        filter: drop-shadow(0px 25px 32px rgba(0, 0, 0, 0.14));
        backdrop-filter: blur(35px);
        border-radius: 45px;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: rgba(255,255,255,.8);
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 4.5rem;
        outline: 0;
        max-height: calc(100vh - 7rem);

        @media screen and (min-width: 768px) {
            max-height: calc(100vh - 3.5rem);
        }

        header {
            color: #9E9E9E;
            text-align: center;
            font-weight: bold;
            font-size: 2rem;
            padding: 3rem 6rem 0.4rem;
        }

        footer {
            padding: 1.2rem 1.75rem;
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 160%;
            text-align: center;
            color: #000000;
            border-top: 1px solid #D7D9DA;
            position: relative;

            &.no-border {
                border: none;
            }
        }
    }

    &__body {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }
        
        &::-webkit-scrollbar-track {
            background: rgba(158,158,158,0.25);
            border-radius: 5px;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #4285F4;
            border-radius: 5px;
        }
    }
}