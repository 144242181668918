.easter-egg {
    &--hooverphonic {
        z-index: 100;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: hooverphonic 4s ease-in;
        animation-fill-mode: forwards;
        border-radius: 50%;
        width: 80vh;
        height: 80vh;
    }
}

@keyframes hooverphonic {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}