.search-box {
    background: #FFFFFF;
    border: 1.45833px solid #D7D9DA;
    border-radius: 35px;
    width: 400px;
    max-width: 90%;

    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    color: #92969B;

    overflow: hidden;
    transition: all .5s;

    &--open {
        padding-bottom: 25px;
    }

    &__input {
        display: block;
        display: flex;
        margin: 0 50px 0 60px;
        
        input {
            background: none;
            width: 100%;
            height: 40px;
            font-size: 20px;
            color: #92969B;
            border: none;

            &:focus-visible {
                outline: 1px solid #D7D9DA;
            }
        }
    }

    &__header {
        position: relative;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
    }

    &__result-item {
        &[aria-selected="true"] {
            color: #4285F4;
        }
    }

    &__logo {
        position: absolute;
        left: 20px;
        top: 20px;
    }

    &__action {
        position: absolute;
        cursor: pointer;
        background: none;
        border: none;
    }

    &__action--open {
        right: 10px;
        top: 15px;
        padding: 15px;
    }

    &__action--close {
        right: 10px;
        top: 10px;
        animation: fadeSpinIn .4s;
        padding: 10px;
    }

    &__results {
        font-size: 20px;
        max-height: 0;
        transition: all .5s;
        height: calc(50vh - 95px);
        overflow: hidden;

        &--open {
            max-height: calc(50vh - 95px);
            overflow-y: auto;
            border-top: 1px solid #F3F3F3;
        }

        ol {
            list-style-position: inside;
            padding-left: 19px;
        }

        li {
            margin: 15px 0;
            cursor: pointer;

            &:hover, &.search-box__results-item--active {
                font-weight: 700;
            }

            button {
                background: none;
                border: none;
                color: #92969B;
                cursor: pointer;

                &:hover, &.search-box__results-item--active {
                    font-weight: 700;
                }
            }
        }

        &--no-results {
            ol {
                list-style-type: none;
            }

            li {
                &:hover {
                    font-weight: 400;
                }

                cursor: not-allowed;
            }
        }
    }
}