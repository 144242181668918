.easter-egg {
    &--squid-game {
        img {
            z-index: 100;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90vw;
            height: 90vw;
            opacity: 0;
            transition: all 1s;
            max-height: 90%;
            max-width: 90%;
        }

        &-active-triangle {
            .easter-egg__squid-game-triangle {
                opacity: 1;
            }
        }
        &-active-circle {
            .easter-egg__squid-game-circle {
                opacity: 1;
            }
        }
        &-active-square {
            .easter-egg__squid-game-square {
                opacity: 1;
            }
        }
    }
}