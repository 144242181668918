@import 'normalize';

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}
body { 
    margin: 0; 
    background-color: #fff; 
    position: relative;
    overflow-x: hidden;
}


.btn {
    cursor: pointer;
}

@import 'fonts';
@import 'typography';
@import 'animation';
@import 'modals';