.lewis-globe-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.footer-buttons-container {
    position: fixed;
    bottom: 2rem;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}