.tile-details {
    background: linear-gradient(183.67deg, rgba(255, 255, 255, 0) 3.59%, rgba(255, 255, 255, 0.95) 26.25%, rgba(255, 255, 255, 0.77) 55.13%, rgba(255, 255, 255, 0.73) 75.39%, rgba(255, 255, 255, 0.77) 100.86%);
    box-sizing: border-box;
    filter: drop-shadow(0px 25px 32px rgba(0, 0, 0, 0.14));
    backdrop-filter: blur(35px);

    .modal__body {
        margin: 0 1rem;
        padding: 0 1rem;
        text-align: center;

        @media screen and (min-width: 768px) {
            margin: 0 2rem;
            padding: 0 2rem;
        }
    }

    &__footer-buttons {
        button {
            margin: 0 1rem;
            background: #FFFFFF;
            border: 1px solid #B8C4C5;
            box-sizing: border-box;
            border-radius: 45px;
            padding: 0.5rem 2rem;
            margin: 0.5rem 1rem;
        }
    }

    &__footer-instruction {
        margin: 0 0 1rem 0;
        line-height: 1;
    }

    &__hero-image {
        border-radius: 2.5rem;
        max-width: 100%;
        max-height: 40vh;

        + .embed-container {
            margin-top: 2.8rem;
        }
    }


    &__content {
        margin: 3.2rem 2rem;
        font-size: 1.6rem;
        line-height: 160%;
        color: rgba(0, 0, 0, 0.8);
        opacity: 0.88;
        text-align: left;

        @media screen and (min-width: 768px) {
            padding: 0 4rem;
            margin: 3.2rem 5rem;
        }
    }

    &__media-source {
        color: #455A64;
        opacity: 0.75;
        margin: 1rem auto;
        padding: 0 1rem;
        font-size: 1.3rem;
        word-break: break-word;
    }

    &__video {
        max-width: 100%;
    }

    &__body {
        h3 {
            font-weight: bold;
            font-size: 3rem;
            line-height: 130%;
            text-align: center;
            color: #4285F4;
            margin: 0 2rem 2.8rem;
        }

        h5 {
            font-size: 20px;
            line-height: 130%;
            text-align: center;
            color: #343A43;
            margin: .8rem 2rem 3.2rem;
        }
    }

    .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } 
    .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}