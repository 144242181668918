.intro-message {
    text-align: center;
    position: fixed;
    font-weight: bold;
    font-size: 30px;
    color: #9E9E9E;
    transform: translate(50%, -50%);
    transition: all 1s;
    z-index: 1000;
    text-decoration: none;
    background: url('../images/icons/trends.svg') no-repeat 0 2px;
    background-size: 0 0;
    
    animation: intoMessageLoaded 4s;
    animation-play-state: paused;
    animation-fill-mode: forwards;

    &--loaded {
        animation-play-state: running;
        font-size: 16px;
        color: #4285F4;
    }
    
    &__logo {
        z-index: 1000;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        transition: all 2s;
        justify-content: center;

        &--hiding {
            opacity: 0;
        }

        img {
            max-width: 100%;
        }
    }
}


@keyframes intoMessageLoaded {
    from {
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
    }
    to {
        top: 20px;
        right: 20px;
        transform: translate(0, 0);
        padding-left: 25px;
        background-size: auto;
    }
}