h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: Googlesans, Helvetica, Arial, sans-serif;
    color: currentColor;
}

h1 {
    font-size: 12rem;
    font-weight: 500;
    margin: 0;
}

h2 {
    font-size: 10rem;
    font-weight: 500;
    margin: 0 0 1rem 0;
    line-height: 7.5rem;
}

body {
    font-family: Googlesans, Helvetica, Arial, sans-serif;
}