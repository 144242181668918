.easter-egg {
    &--tour-de-france {
        z-index: 100;
        position: fixed;
        bottom: -100px;
        animation: tour-de-france 2s ease-in;
        animation-fill-mode: forwards;
        mix-blend-mode: multiply;
    }
}

@keyframes tour-de-france {
    from {
        left: 100%;
    }
    to {
        left: -300px;
    }
}