@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
       opacity: 1;
    }
}

@keyframes fadeSpinIn {
    from {
        opacity: 0;
        transform: rotate(0);
    }
    to {
       opacity: 1;
       transform: rotate(360deg);
    }
}